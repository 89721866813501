import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import {
  Radios,
  TextField,
} from 'mui-rff';
import slugify from 'slugify';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core';
import categoriesSelectors from 'src/store/selectors/categories';
import FieldSet from 'src/components/FieldSet';
import TagsField from '../components/TagsField';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& label': {
      marginRight: theme.spacing(4),
    }
  },
}));

const getCategoriesList = (a, level = 0) => a.reduce((flattened, { name, id, children }) => {
  if (level > 1) {
    name = `${'    '.repeat(level - 1)}|-- ${name}`;
  } else if (level === 1) {
    name = `|-- ${name}`;
  }

  return flattened
    .concat([{
      label: name,
      value: id,
    }])
    .concat(children ? getCategoriesList(children, level + 1) : []);
}, []);

const CategoryDetails = ({
  data,
  setSlug,
}) => {
  const categories = useSelector((state) => categoriesSelectors.getCategories(state));
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja kategorii: ${data.name} [ID: #${data.id}]` : 'Dodaj nową kategorię'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Nazwa"
              name="name"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Kategoria nadrzędna"
              name="parentId"
              select
              variant="outlined"
            >
              <MenuItem value="">
                - Brak -
              </MenuItem>
              {getCategoriesList(categories).map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={data && option.value === data.id}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Opis"
              multiline
              name="description"
              rows={3}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true }}>
              {({ errors, submitFailed }) => (
                <FieldSet
                  title="Typ kategorii"
                  required
                  error={submitFailed && !!errors?.type}
                  helperText={(submitFailed && !!errors?.type && errors.type[0]) || null}
                >
                  <Grid
                    className={classes.radioContainer}
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Radios
                        name="type"
                        showError={() => {}}
                        radioGroupProps={{
                          row: true,
                        }}
                        data={[{
                          value: 'map',
                          label: 'Mapa',
                        },
                        // {
                        //   value: 'plans',
                        //   label: 'Lista planów podróży',
                        // }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </FieldSet>
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TagsField
              initialValue={data?.tags}
              name="tags"
              required
              label="Tagi"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={!!meta.error}
                  helperText={!!meta.error && meta.error[0]}
                  label="Slug"
                  name="slug"
                  required
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              <MenuItem value="visible">
                Widoczny
              </MenuItem>
              <MenuItem value="hidden">
                Ukryty
              </MenuItem>
              {data && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CategoryDetails.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    tags: PropTypes.array,
    status: PropTypes.string,
  }),
  setSlug: PropTypes.func.isRequired,
};

CategoryDetails.defaultProps = {
  data: null,
};

export default CategoryDetails;
