import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  border: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '18.5px 14px',
    borderRadius: '4px',
  },
  helperText: {
    display: 'block',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    position: 'absolute',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '17px',
    transform: 'translate(-6px, -15px) scale(0.75)',
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '& *': {
      color: theme.palette.text.primary,
    },
  },
}));

const FieldSet = ({
  children,
  title,
  required,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Typography
          className={clsx(classes.title, error && classes.error)}
          variant="caption"
        >
          {title}
          {required && ' *'}
        </Typography>
        <Box className={clsx(classes.border, error && classes.error)}>
          {children}
        </Box>
      </Box>
      {(error || helperText) && (
        <Typography
          className={clsx(classes.helperText, error && classes.error)}
          variant="caption"
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

FieldSet.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
};

FieldSet.defaultProps = {
  title: null,
  required: false,
  error: false,
  helperText: null,
};

export default FieldSet;
