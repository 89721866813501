import axios from 'axios';
import {
  clearToken,
  getToken,
  setToken,
} from './jwt';

class Request {
  constructor() {
    this.initConfig();
  }

  initConfig() {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = getToken('token');

    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    axios.defaults.baseURL = baseURL;

    axios.interceptors.request.use(async (config) => ({
      ...config,
      data: config.data,
      params: config.params,
    }));

    axios.interceptors.response.use((response) => ({
      ...response,
      data: response.data,
    }), async (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (!error.response) {
        return Promise.reject(this.parseError({
          response: {
            data: {
              status: 404,
              message: 'Błąd połączenia z bazą danych',
            },
          },
        }));
      }

      if (error.response.status !== 403) {
        return Promise.reject(this.parseError(error));
      }

      if (!axios.defaults.headers.common.Authorization) {
        window.location = '/login';
      }

      // Logout user if token refresh didn't work
      if (error.config.url === '/users/refresh-token/') {
        clearToken('token');

        this.removeAuthorizationToken();

        window.location = '/login';

        return Promise.reject(this.parseError(error));
      }

      // Try request again with new token
      await this.refreshToken();

      const { config } = error;

      delete config.headers.Authorization;

      return axios(config)
        .then((res) => Promise.resolve({
          ...res,
          data: res.data,
        }))
        .catch((err) => Promise.reject(this.parseError(err)));
    });
  }

  setAuthorizationToken = (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  removeAuthorizationToken = () => {
    delete axios.defaults.headers.common.Authorization;
  }

  async refreshToken() {
    const oldToken = getToken('token');

    if (!oldToken) {
      return;
    }

    clearToken('token');

    this.removeAuthorizationToken();

    const response = await axios.post('/users/refresh-token/', { token: oldToken });

    // Set 1 year as cookie's token lifetime - change it in project
    setToken('token', response.data.token, 365);

    this.setAuthorizationToken(response.data.token);
  }

  parseError = (error) => {
    const { response = {} } = error;
    const errors = response?.data?.message;

    return {
      ...response.data,
      message: Array.isArray(errors) ? errors[0] : errors,
      errors: errors && (Array.isArray(errors) ? errors : [errors]),
      _error: response.errors || [],
    };
  }

  get = (...args) => {
    return axios.get(...args);
  }

  post = (...args) => {
    return axios.post(...args);
  }

  options = (...args) => {
    return axios.options(...args);
  }

  patch = (...args) => {
    return axios.patch(...args);
  }

  put = (...args) => {
    return axios.patch(...args);
  }

  delete = (...args) => {
    return axios.delete(...args);
  }
}

export default new Request();
