import React from 'react';

const CableCarIcon = (props) => {
  return (
    <img
      alt="Wyciąg"
      src="/static/images/icons/place_types/cable_car.svg"
      {...props}
    />
  );
};

export default CableCarIcon;
