import React from 'react';

const ChurchIcon = (props) => {
  return (
    <img
      alt="Zabytek sakralny"
      src="/static/images/icons/place_types/church.svg"
      {...props}
    />
  );
};

export default ChurchIcon;
