/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import L from 'leaflet';

const getPlaceMarkerIcon = (type = 'pin') => {
  if (type === 'unknown') {
    type = 'pin';
  }

  let icon;

  try {
    icon = require(`@static/images/icons/place_types/${type}.svg`);
  } catch (error) {
    icon = require('@static/images/icons/pin.svg');
  }

  return new L.Icon({
    iconUrl: icon,
    iconRetinaUrl: icon,
    iconAnchor: null,
    popupAnchor: [0, -18],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(36, 36),
  });
};

export default getPlaceMarkerIcon;
