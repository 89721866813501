import React from 'react';

const WaterfallIcon = (props) => {
  return (
    <img
      alt="Wodospad"
      src="/static/images/icons/place_types/waterfall.svg"
      {...props}
    />
  );
};

export default WaterfallIcon;
