import React from 'react';

const CarOverlookIcon = (props) => {
  return (
    <img
      alt="Wąwóz"
      src="/static/images/icons/place_types/car_overlook.svg"
      {...props}
    />
  );
};

export default CarOverlookIcon;
