export default {
  getPlaceDetails: (state) => state.places.details.data,
  getPlaceDetailsIsFetching: (state) => state.places.details.isFetching,

  getPlaces: (state) => state.places.list.items,
  getPlacesPaginationData: (state) => state.places.list.meta,
  getPlacesIsFetching: (state) => state.places.list.isFetching,

  getPlacesFilters: (state) => state.places.filters,

  getMapPlaces: (state) => state.places.map.data,
  getMapPlacesIsFetching: (state) => state.places.map.isFetching,
};
