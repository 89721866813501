import React from 'react';

const ThermaeIcon = (props) => {
  return (
    <img
      alt="Termy"
      src="/static/images/icons/place_types/thermae.svg"
      {...props}
    />
  );
};

export default ThermaeIcon;
