// import fetch from 'cross-fetch';
import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { Autocomplete } from 'mui-rff';
import {
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core/';
import { useDebouncedCallback } from 'use-debounce';

import request from 'src/utils/request';

export default function TagsField({
  helperText,
  initialValue,
  label,
  rff,
  name,
  onChange,
  required,
}) {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchTagList = useDebouncedCallback(async () => {
    setLoading(true);

    const response = await request.get(`v1/tags/list?limit=10&search=${inputValue}`);

    setOptions(response.data);

    setLoading(false);
  }, 1000);

  const onInput = React.useCallback((event) => {
    const { value } = event.target;
    setInputValue(value);

    if (value !== '') {
      fetchTagList();
    }
  }, [fetchTagList, setInputValue]);

  // To support field without react-final-form
  let valueProp = {};
  if (!rff) {
    valueProp = {
      value: null,
    };
  }

  return (
    <FormSpy subscription={{ errors: true, submitFailed: true }}>
      {({ errors, submitFailed }) => (
        <Autocomplete
          filterSelectedOptions
          getOptionSelected={(option, value) => option?.id === value.id}
          getOptionLabel={(option) => option?.name || ''}
          loading={loading}
          defaultValue={initialValue}
          multiple
          name={name}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInput={onInput}
          onChange={onChange}
          value={undefined}
          options={options}
          {...valueProp}
          renderInput={(params) => (
            <TextField
              {...params}
              error={submitFailed && !!errors?.[name]}
              helperText={(submitFailed && !!errors?.[name] && errors[name][0]) || helperText}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={label}
              variant="outlined"
            />
          )}
          renderOption={(option) => <Typography noWrap>{option?.name || ''}</Typography>}
        />
      )}
    </FormSpy>
  );
}

TagsField.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  initialValue: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  rff: PropTypes.bool,
  onChange: PropTypes.func,
};

TagsField.defaultProps = {
  error: false,
  helperText: null,
  initialValue: [],
  label: 'Użytkownik',
  name: 'user',
  required: false,
  rff: true,
  onChange: null,
};
