import React, {
  forwardRef,
  useEffect,
  useContext,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { AppBarContext } from 'src/utils/appBarContext';

const Page = forwardRef(({
  children,
  appBar,
  title = '',
  ...rest
}, ref) => {
  const { setAppBarProps } = useContext(AppBarContext);

  useEffect(() => {
    setAppBarProps(appBar);
  }, [appBar, setAppBarProps]);

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  appBar: PropTypes.shape(),
  children: PropTypes.node,
  title: PropTypes.string
};

Page.defaultProps = {
  appBar: null,
  children: null,
  title: 'Interameryka',
};

export default Page;
