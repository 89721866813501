import {
  CLEAR_DATA,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_MAP_FAILURE,
  GET_MAP_REQUEST,
  GET_MAP_SUCCESS,
  GET_PLACES_FAILURE,
  GET_PLACES_REQUEST,
  GET_PLACES_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  SET_PLACES_FILTERS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/places';

const initialState = {
  map: {
    current: null,
    isFetching: false,
    data: [],
  },
  filters: {},
  details: {
    isFetching: false,
    data: null,
  },
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const placesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        details: initialState.details,
        list: initialState.list,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case GET_MAP_REQUEST:
      return {
        ...state,
        map: {
          ...state.map,
          isFetching: true,
        },
      };
    case GET_MAP_FAILURE:
      return {
        ...state,
        map: {
          ...state.map,
          isFetching: false,
        },
      };
    case GET_MAP_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          data: action.payload,
          isFetching: false,
        }
      };
    case GET_PLACES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_PLACES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_PLACES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case SET_PLACES_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default placesReducer;
