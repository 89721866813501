import React from 'react';

const MonumentIcon = (props) => {
  return (
    <img
      alt="Pomnik"
      src="/static/images/icons/place_types/monument.svg"
      {...props}
    />
  );
};

export default MonumentIcon;
