export default {
  getFilters: (state) => state.users.filters,

  getUserDetails: (state) => state.users.details.data,
  getUserDetailsIsFetching: (state) => state.users.details.isFetching,

  getUsers: (state) => state.users.list.items,
  getUsersPaginationData: (state) => state.users.list.meta,
  getUsersIsFetching: (state) => state.users.list.isFetching,
};
