import React from 'react';

const TowerIcon = (props) => {
  return (
    <img
      alt="Wieża widokowa"
      src="/static/images/icons/place_types/tower.svg"
      {...props}
    />
  );
};

export default TowerIcon;
