import request from '../../utils/request';

const PREFIX = '[USERS]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearUsersData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createUserRequest = () => ({
  type: CREATE_REQUEST,
});

const createUserSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createUserFailure = () => ({
  type: CREATE_FAILURE,
});

export const createUser = (payload) => (dispatch) => {
  dispatch(createUserRequest());

  return request
    .post('v1/users/', payload)
    .then(({ data }) => {
      dispatch(createUserSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createUserFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteUserRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteUserSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteUserFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteUser = (id) => (dispatch) => {
  dispatch(deleteUserRequest());

  return request
    .delete(`v1/users/${id}`)
    .then(({ data }) => {
      dispatch(deleteUserSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteUserFailure());

      throw error;
    });
};

export const GET_USERS_REQUEST = `${PREFIX} GET_USERS_REQUEST`;
export const GET_USERS_SUCCESS = `${PREFIX} GET_USERS_SUCCESS`;
export const GET_USERS_FAILURE = `${PREFIX} GET_USERS_FAILURE`;

const fetchUsersRequest = () => ({
  type: GET_USERS_REQUEST,
});

const fetchUsersSuccess = (payload) => ({
  payload,
  type: GET_USERS_SUCCESS,
});

const fetchUsersFailure = () => ({
  type: GET_USERS_FAILURE,
});

export const fetchUsers = (filters) => (dispatch) => {
  dispatch(fetchUsersRequest());

  return request
    .get('v1/users/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchUsersSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchUsersFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchUserDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchUserDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchUserDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchUserDetails = (id) => (dispatch) => {
  dispatch(fetchUserDetailsRequest());

  return request
    .get(`v1/users/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchUserDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchUserDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateUserRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateUserSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateUserFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateUser = (id, payload) => (dispatch) => {
  dispatch(updateUserRequest());

  return request
    .patch(`v1/users/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateUserSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateUserFailure());

      throw error;
    });
};
