import React from 'react';

const QuarryIcon = (props) => {
  return (
    <img
      alt="Kamieniołom"
      src="/static/images/icons/place_types/quarry.svg"
      {...props}
    />
  );
};

export default QuarryIcon;
