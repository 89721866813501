export default {
  getFilters: (state) => state.tags.filters,

  getTagDetails: (state) => state.tags.details.data,
  getTagDetailsIsFetching: (state) => state.tags.details.isFetching,

  getTags: (state) => state.tags.list.items,
  getTagsPaginationData: (state) => state.tags.list.meta,
  getTagsIsFetching: (state) => state.tags.list.isFetching,
};
