import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import PerfectScrollbar from 'react-perfect-scrollbar';
import isEqual from 'lodash.isequal';
import omitDeep from 'omit-deep-lodash';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import categoriesSelectors from 'src/store/selectors/categories';

import TableLoading from 'src/components/TableLoading';
import { setOrder } from 'src/store/actions/categories';
import CustomTreeItem from './CategoryTreeItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  categoryIcon: {
    marginRight: theme.spacing(2)
  },
  categoriesContainer: {
    width: '100%',
    height: 'calc(100vh - 230px)',
    padding: 0,
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
  tableHead: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeadCellName: {
    flex: 1,
  },
  tableHeadCellActions: {
    width: 100,
  }
}));

const Results = ({
  openDeleteModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const categories = useSelector((state) => categoriesSelectors.getCategories(state));
  const isFetching = useSelector((state) => categoriesSelectors.getCategoriesIsFetching(state));

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell className={classes.tableHeadCellName}>
                  Nazwa
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell className={classes.tableHeadCellActions} />
              </TableRow>
            </TableHead>
            <TableBody>
              {categories && categories.length > 0 ? (
                <TableRow>
                  <TableCell
                    className={classes.categoriesContainer}
                    colSpan={3}
                  >
                    <SortableTree
                      treeData={categories}
                      onChange={(treeData) => {
                        const hasChangedOrder = !isEqual(omitDeep(categories, ['expanded', 'updated']), omitDeep(treeData, ['expanded', 'updated']));

                        return dispatch(setOrder(treeData, hasChangedOrder));
                      }}
                      nodeContentRenderer={CustomTreeItem}
                      generateNodeProps={({ node }) => ({
                        title: node.name,
                        openDeleteModal,
                      })}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={3}
                    className={classes.noResults}
                  >
                    <CloseIcon className={classes.noResultsIcon} />
                    Brak kategorii
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
