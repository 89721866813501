import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import {
  ArcheologyIcon,
  BeachIcon,
  BikeIcon,
  BreweryIcon,
  BridgeIcon,
  BuildingIcon,
  BunkerIcon,
  CableCarIcon,
  CanyonIcon,
  CarOverlookIcon,
  CastleIcon,
  CaveIcon,
  CementaryIcon,
  ChurchIcon,
  ChurchWoodIcon,
  CityIcon,
  CrossIcon,
  DamIcon,
  HorseIcon,
  KayakIcon,
  LakeIcon,
  LighthouseIcon,
  MarinaIcon,
  MonumentIcon,
  MountainIcon,
  MuseumIcon,
  OverlookIcon,
  PalaceIcon,
  ParkIcon,
  QuarryIcon,
  SkiIcon,
  StonesIcon,
  SwimmingPoolIcon,
  TechnicalIcon,
  TicketIcon,
  ThemeParkIcon,
  ThermaeIcon,
  TowerIcon,
  TrainIcon,
  TrekkingIcon,
  TunnelIcon,
  UndergroundPathIcon,
  UrbexIcon,
  VillageIcon,
  WaterfallIcon,
  WindmillIcon,
  WineIcon,
  ZooIcon,
} from './icons/tourist_attractions';

const useStyles = makeStyles({
  icon: ({ size }) => ({
    width: size,
    height: size,
  }),
});

const PlaceType = ({
  className,
  type,
  ...props
}) => {
  const classes = useStyles(props);

  switch (type) {
    case 'archeology':
      return <ArcheologyIcon className={clsx(classes.icon, className)} />;
    case 'beach':
      return <BeachIcon className={clsx(classes.icon, className)} />;
    case 'bike':
      return <BikeIcon className={clsx(classes.icon, className)} />;
    case 'brewery':
      return <BreweryIcon className={clsx(classes.icon, className)} />;
    case 'bridge':
      return <BridgeIcon className={clsx(classes.icon, className)} />;
    case 'building':
      return <BuildingIcon className={clsx(classes.icon, className)} />;
    case 'bunker':
      return <BunkerIcon className={clsx(classes.icon, className)} />;
    case 'cable_car':
      return <CableCarIcon className={clsx(classes.icon, className)} />;
    case 'canyon':
      return <CanyonIcon className={clsx(classes.icon, className)} />;
    case 'car_overlook':
      return <CarOverlookIcon className={clsx(classes.icon, className)} />;
    case 'castle':
      return <CastleIcon className={clsx(classes.icon, className)} />;
    case 'cave':
      return <CaveIcon className={clsx(classes.icon, className)} />;
    case 'cementary':
      return <CementaryIcon className={clsx(classes.icon, className)} />;
    case 'church':
      return <ChurchIcon className={clsx(classes.icon, className)} />;
    case 'church_wood':
      return <ChurchWoodIcon className={clsx(classes.icon, className)} />;
    case 'city':
      return <CityIcon className={clsx(classes.icon, className)} />;
    case 'cross':
      return <CrossIcon className={clsx(classes.icon, className)} />;
    case 'dam':
      return <DamIcon className={clsx(classes.icon, className)} />;
    case 'horse':
      return <HorseIcon className={clsx(classes.icon, className)} />;
    case 'kayak':
      return <KayakIcon className={clsx(classes.icon, className)} />;
    case 'lake':
      return <LakeIcon className={clsx(classes.icon, className)} />;
    case 'lighthouse':
      return <LighthouseIcon className={clsx(classes.icon, className)} />;
    case 'marina':
      return <MarinaIcon className={clsx(classes.icon, className)} />;
    case 'monument':
      return <MonumentIcon className={clsx(classes.icon, className)} />;
    case 'mountain':
      return <MountainIcon className={clsx(classes.icon, className)} />;
    case 'museum':
      return <MuseumIcon className={clsx(classes.icon, className)} />;
    case 'overlook':
      return <OverlookIcon className={clsx(classes.icon, className)} />;
    case 'palace':
      return <PalaceIcon className={clsx(classes.icon, className)} />;
    case 'park':
      return <ParkIcon className={clsx(classes.icon, className)} />;
    case 'quarry':
      return <QuarryIcon className={clsx(classes.icon, className)} />;
    case 'ski':
      return <SkiIcon className={clsx(classes.icon, className)} />;
    case 'stones':
      return <StonesIcon className={clsx(classes.icon, className)} />;
    case 'swimming_pool':
      return <SwimmingPoolIcon className={clsx(classes.icon, className)} />;
    case 'technical':
      return <TechnicalIcon className={clsx(classes.icon, className)} />;
    case 'theme_park':
      return <ThemeParkIcon className={clsx(classes.icon, className)} />;
    case 'thermae':
      return <ThermaeIcon className={clsx(classes.icon, className)} />;
    case 'tower':
      return <TowerIcon className={clsx(classes.icon, className)} />;
    case 'train':
      return <TrainIcon className={clsx(classes.icon, className)} />;
    case 'trekking':
      return <TrekkingIcon className={clsx(classes.icon, className)} />;
    case 'tunnel':
      return <TunnelIcon className={clsx(classes.icon, className)} />;
    case 'underground_path':
      return <UndergroundPathIcon className={clsx(classes.icon, className)} />;
    case 'urbex':
      return <UrbexIcon className={clsx(classes.icon, className)} />;
    case 'village':
      return <VillageIcon className={clsx(classes.icon, className)} />;
    case 'waterfall':
      return <WaterfallIcon className={clsx(classes.icon, className)} />;
    case 'watermill':
      return <TicketIcon className={clsx(classes.icon, className)} />;
    case 'windmill':
      return <WindmillIcon className={clsx(classes.icon, className)} />;
    case 'wine':
      return <WineIcon className={clsx(classes.icon, className)} />;
    case 'zoo':
      return <ZooIcon className={clsx(classes.icon, className)} />;
    default:
      return <TicketIcon className={clsx(classes.icon, className)} />;
  }
};

PlaceType.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
};

PlaceType.defaultProps = {
  className: null,
  size: 24,
};

export default PlaceType;
