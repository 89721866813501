import React from 'react';

const BuildingIcon = (props) => {
  return (
    <img
      alt="Zabytek"
      src="/static/images/icons/place_types/building.svg"
      {...props}
    />
  );
};

export default BuildingIcon;
