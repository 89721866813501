import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { fetchSettings } from 'src/store/actions/settings';
import {
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import Loading from 'src/components/Loading';

import settingsSelectors from 'src/store/selectors/settings';

import ImageSettings from '../components/ImageSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchSettings());
    };

    fetchData()
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
      });
  }, [dispatch, enqueueSnackbar]);

  const isFetching = useSelector((state) => settingsSelectors.isFetching(state));

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Page
      appBar={{
        title: 'Ustawienia',
      }}
      className={classes.root}
      title="Ustawienia"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ImageSettings />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <Card>
              <CardHeader title="Ustawienia" />
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsView;
