import request from '../../utils/request';

const PREFIX = '[TAGS]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearTagsData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createTagRequest = () => ({
  type: CREATE_REQUEST,
});

const createTagSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createTagFailure = () => ({
  type: CREATE_FAILURE,
});

export const createTag = (payload) => (dispatch) => {
  dispatch(createTagRequest());

  return request
    .post('v1/tags/', payload)
    .then(({ data }) => {
      dispatch(createTagSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createTagFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteTagRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteTagSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteTagFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteTag = (id) => (dispatch) => {
  dispatch(deleteTagRequest());

  return request
    .delete(`v1/tags/${id}`)
    .then(({ data }) => {
      dispatch(deleteTagSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteTagFailure());

      throw error;
    });
};

export const GET_TAGS_REQUEST = `${PREFIX} GET_TAGS_REQUEST`;
export const GET_TAGS_SUCCESS = `${PREFIX} GET_TAGS_SUCCESS`;
export const GET_TAGS_FAILURE = `${PREFIX} GET_TAGS_FAILURE`;

const fetchTagsRequest = () => ({
  type: GET_TAGS_REQUEST,
});

const fetchTagsSuccess = (payload) => ({
  payload,
  type: GET_TAGS_SUCCESS,
});

const fetchTagsFailure = () => ({
  type: GET_TAGS_FAILURE,
});

export const fetchTags = (filters) => (dispatch) => {
  dispatch(fetchTagsRequest());

  return request
    .get('v1/tags/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchTagsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchTagsFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchTagDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchTagDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchTagDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchTagDetails = (id) => (dispatch) => {
  dispatch(fetchTagDetailsRequest());

  return request
    .get(`v1/tags/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchTagDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchTagDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateTagRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateTagSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateTagFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateTag = (id, payload) => (dispatch) => {
  dispatch(updateTagRequest());

  return request
    .patch(`v1/tags/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateTagSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateTagFailure());

      throw error;
    });
};
