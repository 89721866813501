import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from 'mui-rff';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
}));

const ProfileDetails = ({ data }) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja użytkownika: ${data.username} [ID: #${data.id}]` : 'Dodaj nowego użytkownika'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              label="Nazwa użytkownika"
              name="username"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              label="Email"
              name="email"
              required
              type="email"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              <MenuItem value="active">
                Aktywny
              </MenuItem>
              <MenuItem value="inactive">
                Nieaktywny
              </MenuItem>
              <MenuItem value="administrator">
                Administrator
              </MenuItem>
              {data && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justify="space-between"
            spacing={1}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Zarejestrowany
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnie logowanie:
                {' '}
                {data.lastLogin
                  ? moment(data.lastLogin).startOf('minute').fromNow()
                  : 'Nigdy'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

ProfileDetails.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.string,
    id: PropTypes.number,
    lastLogin: PropTypes.string,
    state: PropTypes.string,
    username: PropTypes.string,
  }),
};

ProfileDetails.defaultProps = {
  data: null,
};

export default ProfileDetails;
