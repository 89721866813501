import React from 'react';

const MuseumIcon = (props) => {
  return (
    <img
      alt="Góra"
      src="/static/images/icons/place_types/museum.svg"
      {...props}
    />
  );
};

export default MuseumIcon;
