import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import {
  deleteCategory,
  fetchCategories,
} from 'src/store/actions/categories';
import categoriesSelectors from 'src/store/selectors/categories';

import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import Results from '../components/Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CategoriesListView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const deleteAlertRef = useRef();

  const [selectedId, setSelectedId] = useState(null);

  const isFetching = useSelector((state) => categoriesSelectors.getCategoriesIsFetching(state));

  const fetchData = async () => {
    try {
      await dispatch(fetchCategories());
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy kategorii', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeleteModal = (id) => {
    deleteAlertRef.current.open();
    setSelectedId(id);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteCategory(selectedId));
      deleteAlertRef.current.close();
      setSelectedId(null);
      enqueueSnackbar('Kategoria została usunięta', { variant: 'success' });

      await fetchData();

      return true;
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania kategorii', { variant: 'error' });

      return error;
    }
  };

  return (
    <Page
      className={classes.root}
      title="Kategorie"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results openDeleteModal={openDeleteModal} />
        </Box>
      </Container>
      <AlertDialog
        title="Usuwanie kategorii"
        message="Czy na pewno chcesz usunąć tą kategorię?"
        ref={deleteAlertRef}
        actions={[
          {
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Page>
  );
};

export default CategoriesListView;
