import React from 'react';

const LighthouseIcon = (props) => {
  return (
    <img
      alt="Latarnia morksa"
      src="/static/images/icons/place_types/lighthouse.svg"
      {...props}
    />
  );
};

export default LighthouseIcon;
