import React from 'react';

const UrbexIcon = (props) => {
  return (
    <img
      alt="Urbex"
      src="/static/images/icons/place_types/urbex.svg"
      {...props}
    />
  );
};

export default UrbexIcon;
