import request from 'src/utils/request';

const PREFIX = '[PLACES]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearPlacesData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createPlaceRequest = () => ({
  type: CREATE_REQUEST,
});

const createPlaceSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createPlaceFailure = () => ({
  type: CREATE_FAILURE,
});

export const createPlace = (payload) => (dispatch) => {
  dispatch(createPlaceRequest());

  return request
    .post('v1/places/', payload)
    .then(({ data }) => {
      dispatch(createPlaceSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createPlaceFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deletePlaceRequest = () => ({
  type: DELETE_REQUEST,
});

const deletePlaceSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deletePlaceFailure = () => ({
  type: DELETE_FAILURE,
});

export const deletePlace = (id) => (dispatch) => {
  dispatch(deletePlaceRequest());

  return request
    .delete(`v1/places/${id}`)
    .then(({ data }) => {
      dispatch(deletePlaceSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deletePlaceFailure());

      throw error;
    });
};

export const SET_PLACES_FILTERS = `${PREFIX} SET_PLACES_FILTERS`;
export const setPlacesFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_PLACES_FILTERS,
  });
};

export const GET_MAP_REQUEST = `${PREFIX} GET_MAP_REQUEST`;
export const GET_MAP_SUCCESS = `${PREFIX} GET_MAP_SUCCESS`;
export const GET_MAP_FAILURE = `${PREFIX} GET_MAP_FAILURE`;

const fetchMapPlacesRequest = () => ({
  type: GET_MAP_REQUEST,
});

const fetchMapPlacesSuccess = (payload) => ({
  payload,
  type: GET_MAP_SUCCESS,
});

const fetchMapPlacesFailure = () => ({
  type: GET_MAP_FAILURE,
});

export const fetchMapPlaces = (filters) => (dispatch) => {
  dispatch(fetchMapPlacesRequest());

  return request
    .get('v1/places/map', { params: filters })
    .then(({ data }) => {
      dispatch(fetchMapPlacesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchMapPlacesFailure());

      throw error;
    });
};

export const GET_PLACES_REQUEST = `${PREFIX} GET_PLACES_REQUEST`;
export const GET_PLACES_SUCCESS = `${PREFIX} GET_PLACES_SUCCESS`;
export const GET_PLACES_FAILURE = `${PREFIX} GET_PLACES_FAILURE`;

const fetchPlacesRequest = () => ({
  type: GET_PLACES_REQUEST,
});

const fetchPlacesSuccess = (payload) => ({
  payload,
  type: GET_PLACES_SUCCESS,
});

const fetchPlacesFailure = () => ({
  type: GET_PLACES_FAILURE,
});

export const fetchPlaces = (filters) => (dispatch) => {
  dispatch(fetchPlacesRequest());

  return request
    .get('v1/places/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchPlacesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlacesFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchPlaceDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchPlaceDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchPlaceDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchPlaceDetails = (id) => (dispatch) => {
  dispatch(fetchPlaceDetailsRequest());

  return request
    .get(`v1/places/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchPlaceDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlaceDetailsFailure());

      throw error;
    });
};

export const SET_MAP_TYPE = `${PREFIX} SET_MAP_TYPE`;
export const setMapType = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_MAP_TYPE,
  });
};

export const convertPlaceType = (id, payload) => () => {
  return request
    .post(`v1/places/${id}/convert`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updatePlaceDetailsRequest = () => ({
  type: UPDATE_REQUEST,
});

const updatePlaceDetailsSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updatePlaceDetailsFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updatePlaceDetails = (id, payload) => (dispatch) => {
  dispatch(updatePlaceDetailsRequest());

  return request
    .patch(`v1/places/${id}`, payload)
    .then(({ data }) => {
      dispatch(updatePlaceDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updatePlaceDetailsFailure());

      throw error;
    });
};
