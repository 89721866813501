import React from 'react';

const CrossIcon = (props) => {
  return (
    <img
      alt="Kaplica"
      src="/static/images/icons/place_types/cross.svg"
      {...props}
    />
  );
};

export default CrossIcon;
