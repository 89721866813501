import React from 'react';

const CementaryIcon = (props) => {
  return (
    <img
      alt="Jaskinia"
      src="/static/images/icons/place_types/cementary.svg"
      {...props}
    />
  );
};

export default CementaryIcon;
