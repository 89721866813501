import React, {
  useEffect,
  useState,
  // useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
// import isEqual from 'lodash.isequal';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import {
  clearPlacesData,
  fetchPlaces,
  fetchMapPlaces,
  setPlacesFilters,
} from 'src/store/actions/places';
import placesSelectors from 'src/store/selectors/places';

import Page from 'src/components/Page';
import Map from './Map';
import Toolbar from './Toolbar';
import Results from '../components/Results';

// function usePrevious(value) {
//   const ref = useRef();

//   useEffect(() => {
//     ref.current = value;
//   }, [value]); // Only re-run if value changes

//   return ref.current;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  placeCard: {
    height: '100%'
  }
}));

const PlacesList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isInited, setIsInited] = useState(false);

  const search = searchParams.get('search') || null;
  const currentPage = parseInt(searchParams.get('page') || 1, 10);
  const itemsPerPage = parseInt(searchParams.get('limit') || 12, 10);

  const isMapFetching = useSelector((state) => placesSelectors.getMapPlacesIsFetching(state));
  const mapPlaces = useSelector((state) => placesSelectors.getMapPlaces(state));
  const filters = useSelector((state) => placesSelectors.getPlacesFilters(state));
  // const previousFilters = usePrevious(filters);

  const fetchData = async () => {
    try {
      // if (mapPlaces.length === 0 || !isEqual(previousFilters, filters)) {
      dispatch(fetchMapPlaces({
        search,
        page: currentPage,
        limit: itemsPerPage,
        ...filters,
      }));
      // }
      dispatch(fetchPlaces({
        search,
        page: currentPage,
        limit: itemsPerPage,
        ...filters,
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy miejsc', { variant: 'error' });
    }
  };

  const setFiltersFromSearchParams = () => {
    // eslint-disable-next-line no-unused-vars
    const { page, limit, ...filtersArray } = Object.fromEntries(searchParams);

    dispatch(setPlacesFilters(filtersArray));
  };

  useEffect(() => {
    setIsInited(true);

    return () => {
      dispatch(clearPlacesData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInited) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFiltersFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangePage = (event, value) => {
    setSearchParams({
      page: value,
      limit: itemsPerPage,
      ...filters
    });
  };

  const handleSearch = (values) => {
    setSearchParams({
      page: 1,
      limit: itemsPerPage,
      ...values
    });
  };

  return (
    <Page
      className={classes.root}
      title="Miejsca biwakowe"
    >
      <Container maxWidth={false}>
        <Toolbar
          handleSearch={handleSearch}
          loading={isMapFetching}
        />
        <Box mt={3}>
          <Map
            data={mapPlaces}
            loading={isMapFetching}
          />
        </Box>
        <Box mt={3}>
          <Results handleChangePage={handleChangePage} />
        </Box>
      </Container>
    </Page>
  );
};

export default PlacesList;
