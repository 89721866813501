import React from 'react';

const OverlookIcon = (props) => {
  return (
    <img
      alt="Góra"
      src="/static/images/icons/place_types/overlook.svg"
      {...props}
    />
  );
};

export default OverlookIcon;
