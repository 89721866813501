import {
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
} from '../actions/settings';

const initialState = {
  isFetching: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case GET_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
