import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import UserDetailsView from 'src/views/users/UserDetailsView';
import UsersListView from 'src/views/users/UsersListView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import PlaceDetailsView from 'src/views/places/PlaceDetailsView';
import PlacesListView from 'src/views/places/PlacesListView';
import SettingsView from 'src/views/settings/SettingsView';
import TagsListView from './views/tags/TagsListView';
import TagDetailsView from './views/tags/TagDetailsView';
import CategoriesListView from './views/categories/CategoriesListView';
import CategoryDetailsView from './views/categories/CategoryDetailsView';

const routes = (isUserLogged) => [
  {
    path: 'login',
    element: !isUserLogged ? <MainLayout /> : <Navigate to="/places" />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: isUserLogged ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'users', element: <UsersListView /> },
      { path: 'user/add', element: <UserDetailsView action="add" /> },
      { path: 'user/:id', element: <UserDetailsView action="edit" /> },
      { path: 'categories', element: <CategoriesListView /> },
      { path: 'category/add', element: <CategoryDetailsView action="add" /> },
      { path: 'category/:id', element: <CategoryDetailsView action="edit" /> },
      { path: 'tags', element: <TagsListView /> },
      { path: 'tag/add', element: <TagDetailsView action="add" /> },
      { path: 'tag/:id', element: <TagDetailsView action="edit" /> },
      { path: 'places', element: <PlacesListView /> },
      { path: 'place/add', element: <PlaceDetailsView action="add" /> },
      { path: 'place/:id', element: <PlaceDetailsView action="edit" /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '/', element: <Navigate to="/places" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
];

export default routes;
