import React from 'react';

const CastleIcon = (props) => {
  return (
    <img
      alt="Zamek"
      src="/static/images/icons/place_types/castle.svg"
      {...props}
    />
  );
};

export default CastleIcon;
