import React from 'react';

const TrekkingIcon = (props) => {
  return (
    <img
      alt="Trasa piesza, szlak"
      src="/static/images/icons/place_types/trekking.svg"
      {...props}
    />
  );
};

export default TrekkingIcon;
