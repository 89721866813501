import omitDeep from 'omit-deep-lodash';
import request from '../../utils/request';

const PREFIX = '[CATEGORIES]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearCategoriesData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createCategoryRequest = () => ({
  type: CREATE_REQUEST,
});

const createCategorySuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createCategoryFailure = () => ({
  type: CREATE_FAILURE,
});

export const createCategory = (payload) => (dispatch) => {
  dispatch(createCategoryRequest());

  return request
    .post('v1/categories/', payload)
    .then(({ data }) => {
      dispatch(createCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createCategoryFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteCategoryRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteCategorySuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteCategoryFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteCategory = (id) => (dispatch) => {
  dispatch(deleteCategoryRequest());

  return request
    .delete(`v1/categories/${id}`)
    .then(({ data }) => {
      dispatch(deleteCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteCategoryFailure());

      throw error;
    });
};

export const GET_CATEGORIES_REQUEST = `${PREFIX} GET_CATEGORIES_REQUEST`;
export const GET_CATEGORIES_SUCCESS = `${PREFIX} GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAILURE = `${PREFIX} GET_CATEGORIES_FAILURE`;

const fetchCategoriesRequest = () => ({
  type: GET_CATEGORIES_REQUEST,
});

const fetchCategoriesSuccess = (payload) => ({
  payload,
  type: GET_CATEGORIES_SUCCESS,
});

const fetchCategoriesFailure = () => ({
  type: GET_CATEGORIES_FAILURE,
});

export const fetchCategories = (filters) => (dispatch) => {
  dispatch(fetchCategoriesRequest());

  return request
    .get('v1/categories/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchCategoriesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchCategoriesFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchCategoryDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchCategoryDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchCategoryDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchCategoryDetails = (id) => (dispatch) => {
  dispatch(fetchCategoryDetailsRequest());

  return request
    .get(`v1/categories/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchCategoryDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchCategoryDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const SET_ORDER_REQUEST = `${PREFIX} SET_ORDER_REQUEST`;
export const SET_ORDER_SUCCESS = `${PREFIX} SET_ORDER_SUCCESS`;
export const SET_ORDER_FAILURE = `${PREFIX} SET_ORDER_FAILURE`;

const setOrderRequest = () => ({
  type: SET_ORDER_REQUEST,
});

const setOrderSuccess = (payload) => ({
  payload,
  type: SET_ORDER_SUCCESS,
});

const setOrderFailure = () => ({
  type: SET_ORDER_FAILURE,
});

export const setOrder = (payload, sendRequest = false) => (dispatch) => {
  if (sendRequest) {
    dispatch(setOrderRequest());
  }

  let data = [];
  const flatArray = (category) => {
    category.forEach((item) => {
      data = [
        ...data,
        item,
      ];

      if (item.children) {
        flatArray(item.children);
      }
    });

    return data;
  };

  const prepareCategoriesData = (array, parentId = null) => array.map((item, index) => ({
    order: index,
    id: item.id,
    parentId,
    children: item.children ? prepareCategoriesData(item.children, item.id) : [],
  }));

  if (sendRequest) {
    data = flatArray(prepareCategoriesData(payload));

    return request
      .patch('v1/categories/list', omitDeep(data, ['children']))
      .then(() => {
        dispatch(setOrderSuccess(payload));

        return payload;
      })
      .catch((error) => {
        dispatch(setOrderFailure());

        throw error;
      });
  }

  dispatch(setOrderSuccess(payload));

  return payload;
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

// const updateCategoryRequest = () => ({
//   type: UPDATE_REQUEST,
// });

const updateCategorySuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateCategoryFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateCategory = (id, payload) => (dispatch) => {
  // dispatch(updateCategoryRequest());

  return request
    .patch(`v1/categories/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateCategoryFailure());

      throw error;
    });
};
