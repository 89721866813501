import { combineReducers } from 'redux';
import auth from './auth';
import categories from './categories';
import config from './config';
import places from './places';
import settings from './settings';
import tags from './tags';
import users from './users';

export default combineReducers({
  auth,
  categories,
  config,
  places,
  settings,
  tags,
  users,
});
