import React from 'react';

const CityIcon = (props) => {
  return (
    <img
      alt="Miasto"
      src="/static/images/icons/place_types/city.svg"
      {...props}
    />
  );
};

export default CityIcon;
