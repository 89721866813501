import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  IconButton,
} from '@material-ui/core';
import {
  green,
  red,
} from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const CategoryItem = ({
  category,
  openDeleteModal,
}) => (
  <>
    <Box
      alignItems="center"
      display="flex"
      flex={1}
    >
      {category.name}
    </Box>
    <Box align="center" width={50}>
      {category.status === 'visible' && <VisibilityIcon style={{ color: green[500] }} />}
      {category.status === 'hidden' && <VisibilityOffIcon style={{ color: red[500] }} />}
    </Box>
    <Box align="right">
      <IconButton
        component={RouterLink}
        to={`/category/${category.id}`}
      >
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => openDeleteModal(category.id)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  </>
);

CategoryItem.propTypes = {
  category: PropTypes.shape({
    created: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    tags: PropTypes.array,
    status: PropTypes.string,
  }),
  openDeleteModal: PropTypes.func.isRequired,
};

export default CategoryItem;
