import React from 'react';
import {
  Box,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 401,
    backgroundColor: fade('#fff', 0.5),
  },
});

const TableLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress color="secondary" size={64} />
    </Box>
  );
};

export default TableLoading;
