import React from 'react';

const MarinaIcon = (props) => {
  return (
    <img
      alt="Marina"
      src="/static/images/icons/place_types/marina.svg"
      {...props}
    />
  );
};

export default MarinaIcon;
