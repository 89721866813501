import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  orange,
  red,
} from '@material-ui/core/colors';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import AdministatorIcon from '@material-ui/icons/Stars';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InActiveIcon from '@material-ui/icons/Cancel';

import usersSelectors from 'src/store/selectors/users';

import TableLoading from 'src/components/TableLoading';
import getInitials from 'src/utils/getInitials';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteModal,
}) => {
  const classes = useStyles();

  const users = useSelector((state) => usersSelectors.getUsers(state));
  const pagination = useSelector(
    (state) => usersSelectors.getUsersPaginationData(state),
  );
  const isFetching = useSelector((state) => usersSelectors.getUsersIsFetching(state));

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Użytkownik
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell align="center" width={115}>
                  Status
                </TableCell>
                <TableCell width={220}>
                  Ostatnie logowanie
                </TableCell>
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            {users && (
              <TableBody>
                {users.length > 0 ? users.map((user) => (
                  <TableRow
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={user.avatar && `${STORAGE_URL}/users/${user.id}/${user.avatar}`}
                        >
                          {!user.avatar && getInitials(user.username)}
                        </Avatar>
                        {user.username}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    <TableCell align="center">
                      {user.status === 'active' && <ActiveIcon style={{ color: green[500] }} />}
                      {user.status === 'administrator' && <AdministatorIcon style={{ color: orange[500] }} />}
                      {user.status === 'inactive' && <InActiveIcon style={{ color: red[500] }} />}
                    </TableCell>
                    <TableCell>
                      {user.lastLogin
                        ? moment(user.lastLogin).startOf('minute').fromNow()
                        : 'Nigdy'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/user/${user.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(user.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak użytkowników
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
