import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import FileUploadDialog from 'src/components/FileUploadDialog';

const { PUBLIC_URL } = process.env;
const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  deleteButton: {
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  field: {
    width: '100%',
    '& input': {
      textAlign: 'center',
    },
  },
  media: {
    display: 'block',
    width: '100%',
  },
}));

const CarBox = ({
  data,
  file,
  setFile,
}) => {
  const classes = useStyles();
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const getImage = () => {
    if (file && file[0].data) {
      return file[0].data;
    }

    if (file !== false && data?.image) {
      return `${STORAGE_URL}/tags/${data.id}/${data.image}`;
    }

    return `${PUBLIC_URL}/static/images/place_placeholder.png`;
  };

  const removeImage = () => {
    setFile(data?.image ? false : null);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <img
            className={classes.media}
            src={getImage()}
            alt="Zdjęcie"
          />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          onClick={openFileUploadDialog}
          variant="text"
        >
          {(
            (data?.image && file !== false)
            || (!data?.image && file)
          ) ? 'Zmień zdjęcie'
            : 'Dodaj zdjęcie'}
        </Button>
        {(
          (data?.image && file !== false)
          || (!data?.image && file)
        ) && (
          <Button
            className={classes.deleteButton}
            color="secondary"
            fullWidth
            variant="text"
            onClick={removeImage}
          >
            Usuń zdjęcie
          </Button>
        )}
      </CardActions>
      <FileUploadDialog
        handleClose={closeFileUploadDialog}
        isOpen={isFileUploadOpen}
        setFiles={setFile}
      />
    </Card>
  );
};

CarBox.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    id: PropTypes.number,
  }),
  file: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setFile: PropTypes.func.isRequired,
};

CarBox.defaultProps = {
  data: {},
  file: null,
};

export default CarBox;
