import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Prompt,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import slugify from 'slugify';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import {
  Container,
  CircularProgress,
  Fab,
  Grid,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  clearTagsData,
  createTag,
  deleteTag,
  fetchTagDetails,
  updateTag,
} from 'src/store/actions/tags';
import tagsSelectors from 'src/store/selectors/tags';

import AlertDialog from 'src/components/AlertDialog';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

import ImageBox from './ImageBox';
import TagDetails from './TagDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

// Validation
const schema = Yup.object().shape({
  name: Yup.string()
    .required('Pole jest wymagane'),
  status: Yup.string()
    .required('Pole jest wymagane'),
});

const TagDetailView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const deleteAlertRef = useRef();
  const exitAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const tag = useSelector((state) => tagsSelectors.getTagDetails(state));
  const isFetching = useSelector((state) => tagsSelectors.getTagDetailsIsFetching(state));

  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (action === 'edit') {
      const fetchData = async () => {
        await dispatch(fetchTagDetails(id));
      };

      fetchData()
        .catch(() => {
          enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
        });
    } else {
      // console.log(validate);
    }

    return () => {
      dispatch(clearTagsData());
    };
  }, [action, dispatch, enqueueSnackbar, id]);

  const openExitConfirmModal = () => {
    exitAlertRef.current.open();
    setTimeout(() => { // To disable prompt
      forceUpdate();
    });
  };

  const createSlug = (title) => {
    return slugify(title, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    const tagData = new FormData();

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      tagData.append(key, value);
    });

    if (imageFile != null) {
      tagData.append('image', imageFile ? imageFile[0].file : false);
    }

    try {
      if (action === 'edit') {
        await dispatch(updateTag(id, tagData));
        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createTag(tagData));
        enqueueSnackbar('Dodano nowy tag', { variant: 'success' });

        setTimeout(() => {
          navigate('/tags');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async () => {
    try {
      await dispatch(deleteTag(id));
      enqueueSnackbar('Tag został usunięty', { variant: 'success' });

      setTimeout(() => {
        navigate('/tags');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        tag ? {
          name: tag.name,
          slug: tag.slug,
          image: tag.image,
          description: tag.description,
          status: tag.status,
        } : {
          status: 'visible',
        }
      }
      mutators={{
        setSlug: (args, state, utils) => {
          const slug = createSlug(args[0]);
          if (action === 'add' && !state.fields.slug.modified) {
            utils.changeValue(state, 'slug', () => slug);
          }
        },
      }}
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={makeValidate(schema)}
      render={({
        dirty,
        form,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja tagu' : 'Dodaj nowy tag',
            hasBackButton: true,
            backTo: '/tags',
            onBack: () => {
              if (dirty && !submitSucceeded) {
                openExitConfirmModal();
                return true;
              }
              return false;
            }
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja tagu${tag ? `: ${tag.name} [ID: #${tag.id}]` : ''}`
              : 'Dodaj nowy tag'
          }
        >
          {(action === 'add' || tag) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <ImageBox
                      data={tag}
                      file={imageFile}
                      setFile={setImageFile}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={6}
                    xs={12}
                  >
                    <TagDetails
                      data={tag}
                      setSlug={form.mutators.setSlug}
                    />
                  </Grid>
                </Grid>
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <Tooltip
                      placement="left"
                      title={getSubmitLabel(values.status)}
                    >
                      <Fab
                        aria-label="save"
                        className={clsx(
                          classes.floatingActionButton,
                          values.status === 'deleted' && classes.deleteButton,
                        )}
                        color="secondary"
                        disabled={submitting}
                        type="submit"
                      >
                        {!submitting
                          ? getSubmitIcon(values.status)
                          : <CircularProgress color="secondary" size={26} />}
                      </Fab>
                    </Tooltip>
                  )}
                </FormSpy>
              </Container>
              <Prompt
                when={dirty && !submitSucceeded && !exitAlertRef.current?.isOpen}
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
              />
              <AlertDialog
                title="Nie zapisano zmian"
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                ref={exitAlertRef}
                actions={[
                  {
                    label: 'Wyjdź bez zapisywania',
                    onClick: () => {
                      navigate('/places');
                    },
                  },
                  {
                    autoFocus: true,
                    label: 'Wróć',
                    variant: 'contained',
                  },
                ]}
              />
              <AlertDialog
                title="Czy na pewno chcesz usunąć ten tag?"
                ref={deleteAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    className: classes.deleteButton,
                    disabled: isFetching,
                    label: 'Usuń',
                    loading: isFetching,
                    onClick: () => {
                      onDelete();
                      deleteAlertRef.current.close();
                    },
                  },
                ]}
              />
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

TagDetailView.propTypes = {
  action: PropTypes.string,
};

TagDetailView.defaultProps = {
  action: 'add',
};

export default TagDetailView;
