import React from 'react';

const PalaceIcon = (props) => {
  return (
    <img
      alt="Góra"
      src="/static/images/icons/place_types/palace.svg"
      {...props}
    />
  );
};

export default PalaceIcon;
