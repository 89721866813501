import request from '../../utils/request';

const PREFIX = '[SETTINGS]';

export const GET_SETTINGS_REQUEST = `${PREFIX} GET_SETTINGS_REQUEST`;
export const GET_SETTINGS_SUCCESS = `${PREFIX} GET_SETTINGS_SUCCESS`;
export const GET_SETTINGS_FAILURE = `${PREFIX} GET_SETTINGS_FAILURE`;

const fetchSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});

const fetchSettingsSuccess = (payload) => ({
  payload,
  type: GET_SETTINGS_SUCCESS,
});

const fetchSettingsFailure = () => ({
  type: GET_SETTINGS_FAILURE,
});

export const fetchSettings = () => (dispatch) => {
  dispatch(fetchSettingsRequest());

  return request
    .get('v1/settings/')
    .then(({ data }) => {
      dispatch(fetchSettingsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchSettingsFailure());

      throw error;
    });
};

export const uploadImage = (payload) => () => {
  return request
    .post('v1/settings/picture', payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};
