import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  SET_ORDER_FAILURE,
  SET_ORDER_REQUEST,
  SET_ORDER_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/categories';

const initialState = {
  details: {
    isFetching: false,
    data: null,
  },
  list: {
    isFetching: false,
    items: [],
  },
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload,
          isFetching: false,
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case SET_ORDER_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case SET_ORDER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload,
          isFetching: false,
        }
      };
    case SET_ORDER_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default categoriesReducer;
