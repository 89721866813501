import React from 'react';

const TechnicalIcon = (props) => {
  return (
    <img
      alt="Zabytek techniki"
      src="/static/images/icons/place_types/technical.svg"
      {...props}
    />
  );
};

export default TechnicalIcon;
