import React, {
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Prompt,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import slugify from 'slugify';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import {
  Box,
  CircularProgress,
  Container,
  Fab,
  Grid,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  clearPlacesData,
  createPlace,
  deletePlace,
  fetchPlaceDetails,
  updatePlaceDetails,
} from 'src/store/actions/places';
import placesSelectors from 'src/store/selectors/places';

import AlertDialog from 'src/components/AlertDialog';
import Files from 'src/components/Files';
import Gallery from 'src/components/Gallery';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

import Map from './Map';
import PlaceDetails from './PlaceDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
  contactButton: {
    bottom: 92,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    transform: 'scale(0)',
  },
  contactButtonVisible: {
    transform: 'scale(1)',
  },
}));

const URL_REGEXP = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
const STREETVIEW_REGEXP = /^https?:\/\/(www\.|)google\.[a-z]{2,3}\/maps\/@(-?\d{1,2}\.\d+),(-?\d{1,2}\.\d+),.+$/;

// Validation
const schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  type: Yup.string()
    .required('Pole jest wymagane'),
  latitude: Yup.string().nullable()
    .min(5)
    .required('Pole jest wymagane'),
  longitude: Yup.string().nullable()
    .min(5)
    .required('Pole jest wymagane'),
  description: Yup.string().nullable()
    .required('Pole jest wymagane'),
  tags: Yup.string()
    .required('Pole jest wymagane'),
  website: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(URL_REGEXP, 'Niepoprawny adres URL'),
  streetViewUrl: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(STREETVIEW_REGEXP, 'Niepoprawny adres StreetView'),
  slug: Yup.string().nullable()
    .required('Pole jest wymagane'),
  status: Yup.string().nullable()
    .required('Pole jest wymagane'),
});
const validate = makeValidate(schema);

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const PlaceDetailView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const deleteAlertRef = useRef();
  const editAlertRef = useRef();
  const exitAlertRef = useRef();
  const visibilityAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const [galleryItems, setGalleryItems] = useState([]);
  const [filesItems, setFilesItems] = useState([]);
  const [isMapDraggable, setMapDraggable] = useState(false);
  const [verifiedAlertConfirm, setVerifiedAlertConfirm] = useState(false);

  const place = useSelector((state) => placesSelectors.getPlaceDetails(state));
  const isFetching = useSelector((state) => placesSelectors.getPlaceDetailsIsFetching(state));

  const parseGallery = (files) => {
    const gallery = files.map((file) => {
      return {
        id: file.id,
        data: `${STORAGE_URL}/places/${id}/${file.filename}`,
        file: {
          name: file.filename,
        },
        order: file.order,
      };
    });

    setGalleryItems(gallery);
  };

  const parseFiles = (files) => {
    const items = files.map((file) => {
      return {
        id: file.id,
        data: `${STORAGE_URL}/places/${id}/${file.filename}`,
        file: {
          name: file.filename,
        },
        name: file.name,
        description: file.description,
        order: file.order,
      };
    });

    setFilesItems(items);
  };

  useEffect(() => {
    if (action === 'edit') {
      const fetchData = async () => {
        const data = await dispatch(fetchPlaceDetails(id));
        if (data) {
          parseGallery(data.images);
          parseFiles(data.files);
        }
      };

      fetchData()
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message || 'Wystąpił błąd podczas pobierania danych', { variant: 'error' });
        });
    }

    return () => {
      dispatch(clearPlacesData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, dispatch, enqueueSnackbar, id]);

  const openExitConfirmModal = () => {
    exitAlertRef.current.open();
    setTimeout(() => { // To disable prompt
      forceUpdate();
    });
  };

  const createSlug = (title) => {
    return slugify(title, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    if (
      !verifiedAlertConfirm && values.status === 'hidden'
    ) {
      visibilityAlertRef.current.open();
      return false;
    }

    if (isMapDraggable) {
      editAlertRef.current.open();
      return false;
    }

    const placeData = new FormData();

    values.description = values.description || '';
    values.streetViewUrl = values.streetViewUrl || '';

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;

      if (key === 'tags') {
        placeData.append(key, value.map((v) => v.value || v.id));
      } else {
        placeData.append(key, value);
      }
    });

    if (galleryItems.length > 0) {
      const imagesToRemove = [];

      galleryItems.forEach((item) => {
        if (!item.deleted) {
          if (item.file.path) {
            placeData.append('gallery', item.file);
          }
        } else {
          imagesToRemove.push(item.id);
        }
      });

      placeData.append('galleryOrder', galleryItems.map(({ deleted, file }) => !deleted && file.name));

      if (imagesToRemove.length > 0) {
        placeData.append('imagesToRemove', imagesToRemove.join(','));
      }
    }

    if (filesItems.length > 0) {
      const filesToRemove = [];

      filesItems.forEach((item) => {
        if (!item.deleted) {
          if (item.file.path) {
            placeData.append('files', item.file);
          }
        } else {
          filesToRemove.push(item.id);
        }
      });

      placeData.append('filesData', JSON.stringify(filesItems.map(({ id: fileId, name, description }) => ({ id: fileId, name, description }))));
      placeData.append('filesOrder', filesItems.map(({ deleted, file }) => !deleted && file.name));

      if (filesToRemove.length > 0) {
        placeData.append('filesToRemove', filesToRemove.join(','));
      }
    }

    try {
      if (action === 'edit') {
        const data = await dispatch(updatePlaceDetails(id, placeData));
        parseGallery(data.images);

        setVerifiedAlertConfirm(false);

        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createPlace(placeData));
        enqueueSnackbar('Dodano nowe miejsce', { variant: 'success' });

        setTimeout(() => {
          navigate('/places');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async () => {
    try {
      await dispatch(deletePlace(id));
      enqueueSnackbar('Miejsce zostało usunięte', { variant: 'success' });

      setTimeout(() => {
        navigate('/places');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        place ? {
          title: place.title,
          type: place.type,
          description: place.description,
          latitude: place.latitude,
          longitude: place.longitude,
          website: place.website,
          streetViewUrl: place.streetViewUrl,
          slug: place.slug,
          status: place.status,
        } : {
          status: 'active',
        }
      }
      mutators={{
        setLatLngValues: (args, state, utils) => {
          utils.changeValue(state, 'latitude', () => args[0].lat);
          utils.changeValue(state, 'longitude', () => args[0].lng);
        },
        setSlug: (args, state, utils) => {
          const slug = createSlug(args[0]);
          if (action === 'add' && !state.fields.slug.modified) {
            utils.changeValue(state, 'slug', () => slug);
          }
        },
        setStatus: (args, state, utils) => {
          const status = createSlug(args[0]);
          utils.changeValue(state, 'status', () => status);
        },
      }}
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={validate}
      render={({
        dirty,
        form,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja miejsca' : 'Dodaj nowe miejsce',
            hasBackButton: true,
            backTo: '/places',
            onBack: () => {
              if (dirty && !submitSucceeded) {
                openExitConfirmModal();
                return true;
              }
              return false;
            }
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja miejsca${place ? `: ${place.title}` : ''}`
              : 'Dodaj nowe miejsce'
          }
        >
          {(action === 'add' || place) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  direction="row-reverse"
                  spacing={3}
                >
                  <Grid
                    item
                    lg={8}
                    md={6}
                    xs={12}
                  >
                    <PlaceDetails
                      data={place}
                      setSlug={form.mutators.setSlug}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <Box>
                      <Map
                        data={place}
                        isMapDraggable={isMapDraggable}
                        setMapDraggable={setMapDraggable}
                        setLatLngValues={form.mutators.setLatLngValues}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Gallery
                    items={galleryItems}
                    setItems={setGalleryItems}
                  />
                </Box>
                <Box mt={3}>
                  <Files
                    items={filesItems}
                    setItems={setFilesItems}
                  />
                </Box>
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <>
                      <Tooltip
                        placement="left"
                        title={getSubmitLabel(values.status)}
                      >
                        <Fab
                          aria-label="save"
                          className={clsx(
                            classes.floatingActionButton,
                            values.status === 'deleted' && classes.deleteButton,
                          )}
                          color="secondary"
                          disabled={submitting || isFetching}
                          onClick={() => {
                            form.submit();
                          }}
                          type="button"
                        >
                          {!submitting && !isFetching
                            ? getSubmitIcon(values.status)
                            : <CircularProgress color="secondary" size={26} />}
                        </Fab>
                      </Tooltip>
                    </>
                  )}
                </FormSpy>
              </Container>
              <Prompt
                when={dirty && !submitSucceeded && !exitAlertRef.current?.isOpen}
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
              />
              <AlertDialog
                title="Nie zapisano zmian"
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                ref={exitAlertRef}
                actions={[
                  {
                    label: 'Wyjdź bez zapisywania',
                    onClick: () => {
                      navigate('/places');
                    },
                  },
                  {
                    autoFocus: true,
                    label: 'Wróć',
                    variant: 'contained',
                  },
                ]}
              />
              <AlertDialog
                title="Nie zapisano nowej lokalizacji"
                message="Wykryto zmianę lokalizacji miejsca, ale nie kliknięto w &quot;Zapisz nową lokalizację&quot;."
                ref={editAlertRef}
                actions={[
                  {
                    autoFocus: true,
                    label: 'Zapisz bez zmiany lokalizacji',
                    onClick: () => {
                      editAlertRef.current.close();
                      setMapDraggable(false);

                      setTimeout(() => {
                        form.submit();
                      });
                    },
                    type: 'submit',
                  },
                  {
                    label: 'Wróć',
                    variant: 'contained'
                  },
                ]}
              />
              <AlertDialog
                title="Czy na pewno miejsce ma być nieaktywne?"
                message="Miejsce będzie niewidoczne dla użytkowników, dopóki nie ustawiony zostanie status &quot;Widoczny&quot;."
                ref={visibilityAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    autoFocus: true,
                    label: 'Zapisz',
                    onClick: () => {
                      visibilityAlertRef.current.close();
                      setVerifiedAlertConfirm(true);

                      setTimeout(() => {
                        form.submit();
                      });
                    },
                    type: 'submit',
                    variant: 'contained',
                  },
                ]}
              />
              <AlertDialog
                title="Czy na pewno chcesz usunąć to miejsce?"
                ref={deleteAlertRef}
                actions={
                  [
                    {
                      label: 'Wróć',
                    },
                    {
                      autoFocus: true,
                      className: classes.deleteButton,
                      disabled: isFetching,
                      label: 'Usuń',
                      loading: isFetching,
                      onClick: async () => {
                        onDelete();
                        deleteAlertRef.current.close();
                      },
                      type: 'submit',
                      variant: 'contained',
                    },
                  ]
                }
              />
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

PlaceDetailView.propTypes = {
  action: PropTypes.string,
};

PlaceDetailView.defaultProps = {
  action: 'add',
};

export default PlaceDetailView;
