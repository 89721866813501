export const placeTypeValues = [
  {
    label: 'Basen',
    value: 'swimming_pool',
  },
  {
    label: 'Browar',
    value: 'brewery',
  },
  {
    label: 'Bunkier, twierdza, fort',
    value: 'bunker',
  },
  {
    label: 'Cmentarz',
    value: 'cementary',
  },
  {
    label: 'Góra, szczyt, pasmo',
    value: 'mountain',
  },
  {
    label: 'Jaskinia',
    value: 'cave',
  },
  {
    label: 'Jezioro, rzeka, staw',
    value: 'lake',
  },
  {
    label: 'Kamieniołom',
    value: 'quarry',
  },
  {
    label: 'Kapliczka, krzyż przydrożny',
    value: 'cross',
  },
  {
    label: 'Kolej linowa, wyciąg',
    value: 'cable_car',
  },
  {
    label: 'Latarnia morska',
    value: 'lighthouse',
  },
  {
    label: 'Miasto',
    value: 'city',
  },
  {
    label: 'Most',
    value: 'bridge',
  },
  {
    label: 'Młyn wodny, jaz',
    value: 'watermill',
  },
  {
    label: 'Muzeum, kultura i sztuka',
    value: 'museum',
  },
  {
    label: 'Pałac, dwór',
    value: 'palace',
  },
  {
    label: 'Park, ogród, rezerwat',
    value: 'park',
  },
  {
    label: 'Park rozrywki',
    value: 'theme_park',
  },
  {
    label: 'Plaża',
    value: 'beach',
  },
  {
    label: 'Podziemna trasa turystyczna',
    value: 'underground_path',
  },
  {
    label: 'Pomnik, fontanna, mała architektura',
    value: 'monument',
  },
  {
    label: 'Punkt widokowy',
    value: 'overlook',
  },
  {
    label: 'Rejs statkiem, marina',
    value: 'marina',
  },
  {
    label: 'Skała, skalne miasto',
    value: 'stones',
  },
  {
    label: 'Skansen, wiejska zabudowa',
    value: 'village',
  },
  {
    label: 'Stanowisko archeologiczne',
    value: 'archeology',
  },
  {
    label: 'Trasa kajakowa, wypożyczalnia',
    value: 'kayak',
  },
  {
    label: 'Trasa kolejowa, wąskotorowa',
    value: 'train',
  },
  {
    label: 'Trasa konna, stadnina',
    value: 'horse',
  },
  {
    label: 'Trasa narciarskia, stok',
    value: 'ski',
  },
  {
    label: 'Trasa piesza, szlak',
    value: 'trekking',
  },
  {
    label: 'Trasa rowerowa, wypożyczalnia',
    value: 'bike',
  },
  {
    label: 'Trasa widokowa samochodowa',
    value: 'car_overlook',
  },
  {
    label: 'Tunel',
    value: 'tunnel',
  },
  {
    label: 'Urbex',
    value: 'urbex',
  },
  {
    label: 'Wąwóz, kanion',
    value: 'canyon',
  },
  {
    label: 'Wiatrak',
    value: 'windmill',
  },
  {
    label: 'Wieża widokowa',
    value: 'tower',
  },
  {
    label: 'Winnica',
    value: 'wine',
  },
  {
    label: 'Wodospad',
    value: 'waterfall',
  },
  {
    label: 'Zabytek techniki',
    value: 'technical',
  },
  {
    label: 'Zabytek sakralny drewniany',
    value: 'church_wood',
  },
  {
    label: 'Zabytek sakralny murowany',
    value: 'church',
  },
  {
    label: 'Zabytkowa zabudowa',
    value: 'building',
  },
  {
    label: 'Zamek',
    value: 'castle',
  },
  {
    label: 'Zapora wodna',
    value: 'dam',
  },
  {
    label: 'Zoo',
    value: 'zoo',
  },
  {
    label: 'Źródła termalne',
    value: 'thermae',
  },
  {
    label: 'Inna atrakcja',
    value: 'other',
  },
];

export const campingTypeValues = [
  {
    label: 'Kemping',
    value: 'camping',
  },
  {
    label: 'Pole biwakowe',
    value: 'bivouac',
  },
];

export const otherTypeValues = [
  {
    label: 'Atrakcja turystyczna',
    value: 'tourist_attraction',
  },
  {
    label: 'Serwis',
    value: 'service',
  },
];

export const serviceTypeValues = [
  {
    label: 'Prysznic',
    value: 'shower',
  },
  {
    label: 'Pralnia',
    value: 'laundry',
  },
  {
    label: 'Sklep kempingowy',
    value: 'camp_store',
  },
  {
    label: 'Serwis kampera',
    value: 'camper_station',
  },
  {
    label: 'Punkt napełniania butli LPG',
    value: 'lpg',
  },
  {
    label: 'Woda',
    value: 'water',
  },
  {
    label: 'Wypożyczalnia / Salon / Komis',
    value: 'caravan_salon',
  },
  {
    label: 'Inny serwis',
    value: 'other',
  },
];

export const groundTypeValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Utwardzony',
    value: 'concrate',
  },
  {
    label: 'Szuter',
    value: 'gravel',
  },
  {
    label: 'Gruntowy',
    value: 'dirt',
  },
  {
    label: 'Trawa',
    value: 'grass',
  },
  {
    label: 'Piasek',
    value: 'sand',
  },
  {
    label: 'Inny',
    value: 'other',
  },
];

export const accessTypeValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Utwardzoną',
    value: 'concrate',
  },
  {
    label: 'Szutrową',
    value: 'gravel',
  },
  {
    label: 'Gruntową',
    value: 'dirt',
  },
  {
    label: 'Trawą',
    value: 'grass',
  },
  {
    label: 'Piaskiem',
    value: 'sand',
  },
  {
    label: 'Inną',
    value: 'other',
  },
];

export const accessForValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Wszystkich',
    value: 'all',
  },
  {
    label: 'Tylko Osobówki/Małe kampery',
    value: 'cars',
  },
  {
    label: 'Tylko 4x4',
    value: '4x4',
  },
];

export const gsmSignalValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Dobry',
    value: 'good',
  },
  {
    label: 'Słaby',
    value: 'bad',
  },
  {
    label: 'Brak',
    value: 'none',
  },
];

export const campingAtmosphereValues = [
  {
    label: 'Spokojna',
    value: 'calm',
  },
  {
    label: 'Rodzinna (dzieci)',
    value: 'family',
  },
  {
    label: 'Rozrywkowa',
    value: 'entertaining',
  },
  {
    label: 'Uniwersalna',
    value: 'universal',
  },
];

export const campingCategoryValues = [
  {
    label: 'Bez kategoryzacji',
    value: 0,
  },
  {
    label: '1 gwiazdka - *',
    value: 1,
  },
  {
    label: '2 gwiazdki - **',
    value: 2,
  },
  {
    label: '3 gwiazdki - ***',
    value: 3,
  },
  {
    label: '4 gwiazdki - ****',
    value: 4,
  },
  {
    label: '5 gwiazdki - *****',
    value: 5,
  },
];

export const campingDurationValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Całoroczny',
    value: 'year_round',
  },
  {
    label: 'Sezonowy',
    value: 'season',
  },
];

export const campingPetsValues = [
  {
    label: 'Nie określono',
    value: 'unknown',
  },
  {
    label: 'Dozwolone',
    value: 'permited',
  },
  {
    label: 'Zabronione',
    value: 'prohibited',
  },
];

export const statusValues = [
  {
    label: 'Widoczny',
    value: 'active',
  },
  {
    label: 'Niezweryfikowany',
    value: 'unverified',
  },
  {
    label: 'W trakcie weryfikacji',
    value: 'in_review',
  },
  {
    label: 'Ukryty',
    value: 'hidden',
  },
];

export const touristAttractionTypeValues = [
  {
    label: 'Basen',
    value: 'swimming_pool',
  },
  {
    label: 'Browar',
    value: 'brewery',
  },
  {
    label: 'Bunkier, twierdza, fort',
    value: 'bunker',
  },
  {
    label: 'Cmentarz',
    value: 'cementary',
  },
  {
    label: 'Góra, szczyt, pasmo',
    value: 'mountain',
  },
  {
    label: 'Jaskinia',
    value: 'cave',
  },
  {
    label: 'Jezioro, rzeka, staw',
    value: 'lake',
  },
  {
    label: 'Kamieniołom',
    value: 'quarry',
  },
  {
    label: 'Kapliczka, krzyż przydrożny',
    value: 'cross',
  },
  {
    label: 'Kolej linowa, wyciąg',
    value: 'cable_car',
  },
  {
    label: 'Latarnia morska',
    value: 'lighthouse',
  },
  {
    label: 'Miasto',
    value: 'city',
  },
  {
    label: 'Most',
    value: 'bridge',
  },
  {
    label: 'Młyn wodny, jaz',
    value: 'watermill',
  },
  {
    label: 'Muzeum, kultura i sztuka',
    value: 'museum',
  },
  {
    label: 'Pałac, dwór',
    value: 'palace',
  },
  {
    label: 'Park, ogród, rezerwat',
    value: 'park',
  },
  {
    label: 'Park rozrywki',
    value: 'theme_park',
  },
  {
    label: 'Plaża',
    value: 'beach',
  },
  {
    label: 'Podziemna trasa turystyczna',
    value: 'underground_path',
  },
  {
    label: 'Pomnik, fontanna, mała architektura',
    value: 'monument',
  },
  {
    label: 'Punkt widokowy',
    value: 'overlook',
  },
  {
    label: 'Rejs statkiem, marina',
    value: 'marina',
  },
  {
    label: 'Skała, skalne miasto',
    value: 'stones',
  },
  {
    label: 'Skansen, wiejska zabudowa',
    value: 'village',
  },
  {
    label: 'Stanowisko archeologiczne',
    value: 'archeology',
  },
  {
    label: 'Trasa kajakowa, wypożyczalnia',
    value: 'kayak',
  },
  {
    label: 'Trasa kolejowa, wąskotorowa',
    value: 'train',
  },
  {
    label: 'Trasa konna, stadnina',
    value: 'horse',
  },
  {
    label: 'Trasa narciarskia, stok',
    value: 'ski',
  },
  {
    label: 'Trasa piesza, szlak',
    value: 'trekking',
  },
  {
    label: 'Trasa rowerowa, wypożyczalnia',
    value: 'bike',
  },
  {
    label: 'Trasa widokowa samochodowa',
    value: 'car_overlook',
  },
  {
    label: 'Tunel',
    value: 'tunnel',
  },
  {
    label: 'Urbex',
    value: 'urbex',
  },
  {
    label: 'Wąwóz, kanion',
    value: 'canyon',
  },
  {
    label: 'Wiatrak',
    value: 'windmill',
  },
  {
    label: 'Wieża widokowa',
    value: 'tower',
  },
  {
    label: 'Winnica',
    value: 'wine',
  },
  {
    label: 'Wodospad',
    value: 'waterfall',
  },
  {
    label: 'Zabytek techniki',
    value: 'technical',
  },
  {
    label: 'Zabytek sakralny drewniany',
    value: 'church_wood',
  },
  {
    label: 'Zabytek sakralny murowany',
    value: 'church',
  },
  {
    label: 'Zabytkowa zabudowa',
    value: 'building',
  },
  {
    label: 'Zamek',
    value: 'castle',
  },
  {
    label: 'Zapora wodna',
    value: 'dam',
  },
  {
    label: 'Zoo',
    value: 'zoo',
  },
  {
    label: 'Źródła termalne',
    value: 'thermae',
  },
  {
    label: 'Inna atrakcja',
    value: 'other',
  },
];
