import React from 'react';

const BridgeIcon = (props) => {
  return (
    <img
      alt="Most"
      src="/static/images/icons/place_types/bridge.svg"
      {...props}
    />
  );
};

export default BridgeIcon;
