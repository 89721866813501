import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { TextField } from 'mui-rff';
import slugify from 'slugify';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const TagDetails = ({
  data,
  setSlug,
}) => {
  return (
    <Card>
      <CardHeader
        title={data ? `Edycja tagu: ${data.name} [ID: #${data.id}]` : 'Dodaj nowy tag'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalOfferIcon />
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Nazwa"
              name="name"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Opis"
              multiline
              name="description"
              rows={3}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={!!meta.error}
                  helperText={!!meta.error && meta.error[0]}
                  label="Slug"
                  name="slug"
                  required
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              <MenuItem value="visible">
                Widoczny
              </MenuItem>
              <MenuItem value="hidden">
                Ukryty
              </MenuItem>
              {data && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TagDetails.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    status: PropTypes.string,
  }),
  setSlug: PropTypes.func.isRequired,
};

TagDetails.defaultProps = {
  data: null,
};

export default TagDetails;
