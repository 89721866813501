import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  red,
} from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TagIcon from '@material-ui/icons/LocalOffer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import tagsSelectors from 'src/store/selectors/tags';

import TableLoading from 'src/components/TableLoading';

const useStyles = makeStyles((theme) => ({
  root: {},
  tagIcon: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteModal,
}) => {
  const classes = useStyles();

  const tags = useSelector((state) => tagsSelectors.getTags(state));
  const pagination = useSelector(
    (state) => tagsSelectors.getTagsPaginationData(state),
  );
  const isFetching = useSelector((state) => tagsSelectors.getTagsIsFetching(state));

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nazwa
                </TableCell>
                <TableCell align="center" width={115}>
                  Miejsc
                </TableCell>
                <TableCell align="center" width={115}>
                  Status
                </TableCell>
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            {tags && (
              <TableBody>
                {tags.length > 0 ? tags.map((tag) => (
                  <TableRow
                    hover
                    key={tag.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <TagIcon className={classes.tagIcon} />
                        {tag.name}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {tag.placesCount}
                    </TableCell>
                    <TableCell align="center">
                      {tag.status === 'visible' && <VisibilityIcon style={{ color: green[500] }} />}
                      {tag.status === 'hidden' && <VisibilityOffIcon style={{ color: red[500] }} />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/tag/${tag.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(tag.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak tagów
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
