import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TextField,
} from 'mui-rff';
import { Form } from 'react-final-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import AddPlaceIcon from '@material-ui/icons/AddLocation';
import FilterIcon from '@material-ui/icons/FilterList';
import VerifyIcon from '@material-ui/icons/LibraryAddCheck';

import placesSelectors from 'src/store/selectors/places';
import {
  placeTypeValues,
  statusValues,
} from 'src/utils/enums';
import PlaceType from 'src/components/PlaceType';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  countryIcon: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  filters: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.secondary.dark,
    }
  },
  filtersActive: {
    background: theme.palette.secondary.main,
  },
  filtersActions: {
    justifyContent: 'center',
  },
  placeTypeIcon: {
    marginRight: theme.spacing(1),
  },
  searchBar: {
    flex: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  searchBarContainer: {
    display: 'flex',
  },
  typeSelect: {
    '& img': {
      width: 17,
      height: 17,
      verticalAlign: 'middle',
    },
  },
}));

const Toolbar = ({ handleSearch, loading }) => {
  const classes = useStyles();
  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const filters = useSelector((state) => placesSelectors.getPlacesFilters(state));

  useEffect(() => {
    setFiltersOpen(Object.keys(filters).length > (filters.search ? 1 : 0));
  }, [filters]);

  const handleFilter = (values = {}) => {
    const filtersObject = values;
    Object.keys(values).forEach((key) => {
      if (values[key] === false) {
        delete filtersObject[key];
      }
    });

    handleSearch(filtersObject);
  };

  const filterByToVerified = () => {
    handleSearch({
      status: statusValues.find((item) => item.value === 'unverified').value,
    });
  };

  const handleSearchInputChange = (value) => {
    const filtersArray = {
      ...filters,
    };

    if (value) {
      filtersArray.search = value;
    } else {
      delete filtersArray.search;
    }

    handleFilter(filtersArray);
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        md={6}
        xs={12}
      >
        <Box className={classes.searchBarContainer}>
          <SearchBar
            className={classes.searchBar}
            onRequestSearch={handleSearchInputChange}
            onCancelSearch={handleSearchInputChange}
            placeholder="Szukaj miejsca"
            value={filters.search || ''}
          />
          <IconButton
            color="secondary"
            className={clsx(classes.filters, isFiltersOpen && classes.filtersActive)}
            onClick={() => setFiltersOpen(!isFiltersOpen)}
            variant="contained"
          >
            <FilterIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Box display="flex" justifyContent="flex-end">
          <Box marginRight={1}>
            <Button
              color="primary"
              startIcon={<VerifyIcon />}
              onClick={filterByToVerified}
              variant="outlined"
            >
              Do weryfikacji
            </Button>
          </Box>
          <Button
            color="secondary"
            component={RouterLink}
            startIcon={<AddPlaceIcon />}
            to="/place/add"
            variant="contained"
          >
            Dodaj miejsce
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Collapse in={isFiltersOpen}>
          <Form
            initialValues={filters}
            onSubmit={handleFilter}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <TextField
                          className={classes.typeSelect}
                          fullWidth
                          label="Typ miejsca"
                          name="type"
                          select
                          variant="outlined"
                        >
                          <MenuItem value="">
                            Wszystkie
                          </MenuItem>
                          {placeTypeValues.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              <PlaceType
                                className={classes.placeTypeIcon}
                                type={option.value}
                              />
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Status"
                          name="status"
                          select
                          variant="outlined"
                        >
                          <MenuItem value="all">
                            Wszystkie
                          </MenuItem>
                          <MenuItem value="">
                            {statusValues[0].label}
                          </MenuItem>
                          {statusValues.map((option, index) => index > 0 && (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.filtersActions}>
                    <Button
                      color="secondary"
                      disabled={loading}
                      size="small"
                      type="submit"
                      variant="contained"
                    >
                      Filtruj
                      {loading && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
                    </Button>
                    <Button
                      onClick={() => {
                        if (filters.search) {
                          handleFilter({ search: filters.search });
                        } else {
                          handleFilter();
                        }
                      }}
                      size="small"
                      type="reset"
                    >
                      Wyczyść
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Toolbar;
