import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Toolbar = () => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            component={RouterLink}
            startIcon={<AddIcon />}
            to="/category/add"
            variant="contained"
          >
            Dodaj kategorię
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Toolbar;
