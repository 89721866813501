/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  Grid,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import AudioFileIcon from '@material-ui/icons/Audiotrack';

import FieldSet from 'src/components/FieldSet';
import FileUploadDialog from 'src/components/FileUploadDialog';

const useStyles = makeStyles((theme) => {
  return {
    addButton: {
      width: 160,
      height: 100,
    },
    fileIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      '& svg': {
        fill: '#fff',
      }
    },
    item: {
      position: 'relative',
      width: '100%',
      marginBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      fontSize: 48,
      '&:hover button': {
        opacity: 1,
      },
      '& ~ &': {
        paddingTop: theme.spacing(2),
      },
    },
    deleteButton: {
      position: 'absolute',
      bottom: 16,
      left: 6,
      opacity: 0,
      backgroundColor: 'rgba(200, 200, 200, 0.7)',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        '& svg': {
          fill: theme.palette.white,
        },
      },
    },
    zoomButton: {
      position: 'absolute',
      top: 6,
      right: 6,
      opacity: 0,
      backgroundColor: 'rgba(200, 200, 200, 0.7)',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& svg': {
          fill: '#fff',
        },
      },
    },
    noResults: {
      width: '100%',
      height: '100%',
      color: theme.palette.text.disabled,
      textAlign: 'center',
    },
    noResultsIcon: {
      display: 'block',
      margin: '30px auto 0',
      fontSize: 64,
    },
  };
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 ${8}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'rgb(251 253 255)' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'rgb(251 253 255)' : '#fff',
  borderRadius: 4,
});

const Files = ({
  items,
  setItems,
  variant,
}) => {
  const classes = useStyles({ variant });
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    removed.order = endIndex;
    result.splice(endIndex, 0, removed);

    return result;
  };

  const setFilename = (index, name) => {
    items[index].name = name;
    setItems([...items]);
  };

  const setDescription = (index, description) => {
    items[index].description = description;
    setItems([...items]);
  };

  const onAdd = (newFiles) => {
    setItems([
      ...items,
      ...newFiles.map((item) => {
        item.name = item.file.name;

        return item;
      }),
    ]);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const onDelete = (file) => {
    const index = items.findIndex((item) => item === file);

    if (items[index].id) {
      items[index].deleted = true;
    } else {
      items = items.filter((item) => item !== file);
    }

    setItems([...items]);
  };

  const renderGallery = () => {
    return items?.length > 0 || variant === 'outlined' ? (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <Box>
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => !item.deleted && (
                  <Draggable key={item.file.name} draggableId={item.file.name} index={index}>
                    {(dProvided, dSnapshot) => (
                      <Box
                        ref={dProvided.innerRef}
                        {...dProvided.draggableProps}
                        {...dProvided.dragHandleProps}
                        style={getItemStyle(
                          dSnapshot.isDragging,
                          dProvided.draggableProps.style
                        )}
                        className={classes.item}
                      >
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            xs={4}
                          >
                            <Grid
                              container
                              spacing={3}
                            >
                              <Grid
                                item
                                xs={12}
                              >
                                <Box className={classes.fileIcon}>
                                  <AudioFileIcon />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                              >
                                <audio
                                  controls
                                  src={item.data}
                                  style={{
                                    width: '100%',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                          >
                            <Grid
                              container
                              spacing={3}
                            >
                              <Grid
                                item
                                xs={12}
                              >
                                <TextField
                                  fullWidth
                                  label="Nazwa"
                                  name="name"
                                  required
                                  variant="outlined"
                                  onInput={(event) => {
                                    setFilename(index, event.target.value);
                                  }}
                                  value={item.name}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                              >
                                <TextField
                                  fullWidth
                                  label="Opis"
                                  multiline
                                  name="description"
                                  rows={3}
                                  onInput={(event) => {
                                    setDescription(index, event.target.value);
                                  }}
                                  value={item.description}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <IconButton
                          className={classes.deleteButton}
                          onClick={() => onDelete(item)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {variant === 'outlined' && (
                  <Button
                    className={classes.addButton}
                    color="primary"
                    fullWidth
                    variant="text"
                    onClick={openFileUploadDialog}
                  >
                    <AddPhotoIcon />
                  </Button>
                )}
              </div>
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <Box
        width="100%"
        height={150}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.noResults}>
          <CloseIcon className={classes.noResultsIcon} />
          Brak elementów do wyświetlenia
        </Typography>
      </Box>
    );
  };

  const renderCard = () => (
    <Card>
      <CardHeader title="Pliki" />
      <Divider />
      <CardContent>
        {renderGallery()}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={openFileUploadDialog}
        >
          Dodaj pliki
        </Button>
      </CardActions>
    </Card>
  );

  const renderOutlined = () => (
    <FieldSet title="Pliki">
      {renderGallery()}
    </FieldSet>
  );

  return (
    <>
      {variant === 'card' && renderCard()}
      {variant === 'outlined' && renderOutlined()}
      <FileUploadDialog
        acceptedFiles={['audio/mpeg']}
        handleClose={closeFileUploadDialog}
        filesLimit={10}
        isOpen={isFileUploadOpen}
        setFiles={onAdd}
      />
    </>
  );
};

Files.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setItems: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['card', 'outlined']),
};

Files.defaultProps = {
  items: [],
  variant: 'card',
};

export default Files;
