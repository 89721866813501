import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Prompt,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import {
  Box,
  Container,
  CircularProgress,
  Fab,
  Grid,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  clearUsersData,
  createUser,
  deleteUser,
  fetchUserDetails,
  updateUser,
} from 'src/store/actions/users';
import usersSelectors from 'src/store/selectors/users';

import AlertDialog from 'src/components/AlertDialog';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

import AvatarBox from './AvatarBox';
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

// Validation
const schema = Yup.object().shape({
  username: Yup.string()
    .required('Pole jest wymagane'),
  email: Yup.string()
    .required('Pole jest wymagane')
    .email('Niepoprawny adres Email'),
  password: Yup.string().nullable()
    .min(6, 'Hasło musi mieć co najmniej 6 znaków'),
  confirm_password: Yup.string().nullable()
    .when('password', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
        .oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same'),
      otherwise: Yup.string(),
    }),
  status: Yup.string()
    .required('Pole jest wymagane'),
});
const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Pole jest wymagane')
    .min(6, 'Hasło musi mieć co najmniej 6 znaków'),
});

const UserDetailView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const deleteAlertRef = useRef();
  const exitAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const user = useSelector((state) => usersSelectors.getUserDetails(state));
  const isFetching = useSelector((state) => usersSelectors.getUserDetailsIsFetching(state));

  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    if (action === 'edit') {
      const fetchData = async () => {
        await dispatch(fetchUserDetails(id));
      };

      fetchData()
        .catch(() => {
          enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
        });
    } else {
      // console.log(validate);
    }

    return () => {
      dispatch(clearUsersData());
    };
  }, [action, dispatch, enqueueSnackbar, id]);

  const openExitConfirmModal = () => {
    exitAlertRef.current.open();
    setTimeout(() => { // To disable prompt
      forceUpdate();
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    const userData = new FormData();

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      userData.append(key, value);
    });

    if (avatarFile !== null) {
      userData.append('avatar', avatarFile ? avatarFile[0].file : false);
    }

    try {
      if (action === 'edit') {
        await dispatch(updateUser(id, userData));
        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createUser(userData));
        enqueueSnackbar('Dodano nowego użytkownika', { variant: 'success' });

        setTimeout(() => {
          navigate('/users');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async () => {
    try {
      await dispatch(deleteUser(id));
      enqueueSnackbar('Użytkownik został usunięty', { variant: 'success' });

      setTimeout(() => {
        navigate('/users');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        user ? {
          username: user.username,
          email: user.email,
          status: user.status,
        } : {
          status: 'active',
        }
      }
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={action === 'add'
        ? makeValidate(schema.concat(newPasswordSchema))
        : makeValidate(schema)}
      render={({
        dirty,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja użytkownika' : 'Dodaj nowego użytkownika',
            hasBackButton: true,
            backTo: '/users',
            onBack: () => {
              if (dirty && !submitSucceeded) {
                openExitConfirmModal();
                return true;
              }
              return false;
            }
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja użytkownika${user ? `: ${user.username} [ID: #${user.id}]` : ''}`
              : 'Dodaj nowego użytkownika'
          }
        >
          {(action === 'add' || user) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <AvatarBox
                      data={user}
                      file={avatarFile}
                      setFile={setAvatarFile}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={6}
                    xs={12}
                  >
                    <ProfileDetails data={user} />
                    <Box mt={3}>
                      <ChangePassword action={action} />
                    </Box>
                  </Grid>
                </Grid>
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <Tooltip
                      placement="left"
                      title={getSubmitLabel(values.status)}
                    >
                      <Fab
                        aria-label="save"
                        className={clsx(
                          classes.floatingActionButton,
                          values.status === 'deleted' && classes.deleteButton,
                        )}
                        color="secondary"
                        disabled={submitting}
                        type="submit"
                      >
                        {!submitting
                          ? getSubmitIcon(values.status)
                          : <CircularProgress color="secondary" size={26} />}
                      </Fab>
                    </Tooltip>
                  )}
                </FormSpy>
              </Container>
              <Prompt
                when={dirty && !submitSucceeded && !exitAlertRef.current?.isOpen}
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
              />
              <AlertDialog
                title="Nie zapisano zmian"
                message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                ref={exitAlertRef}
                actions={[
                  {
                    label: 'Wyjdź bez zapisywania',
                    onClick: () => {
                      navigate('/places');
                    },
                  },
                  {
                    autoFocus: true,
                    label: 'Wróć',
                    variant: 'contained',
                  },
                ]}
              />
              <AlertDialog
                title="Czy na pewno chcesz usunąć to miejsce?"
                ref={deleteAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    className: classes.deleteButton,
                    disabled: isFetching,
                    label: 'Usuń',
                    loading: isFetching,
                    onClick: () => {
                      onDelete();
                      deleteAlertRef.current.close();
                    },
                  },
                ]}
              />
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

UserDetailView.propTypes = {
  action: PropTypes.string,
};

UserDetailView.defaultProps = {
  action: 'add',
};

export default UserDetailView;
