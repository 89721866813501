import React from 'react';

const ArcheologyIcon = (props) => {
  return (
    <img
      alt="Stanowisko archeologiczne"
      src="/static/images/icons/place_types/archeology.svg"
      {...props}
    />
  );
};

export default ArcheologyIcon;
