import React from 'react';

const WineIcon = (props) => {
  return (
    <img
      alt="Winnica"
      src="/static/images/icons/place_types/wine.svg"
      {...props}
    />
  );
};

export default WineIcon;
