import React, {
  useContext,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBarContext } from 'src/utils/appBarContext';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Group as UsersIcon,
  Input as LogoutIcon,
  Place as MapPinIcon,
  Bookmarks as CategoriesIcon,
  LocalOffer as TagIcon,
} from '@material-ui/icons/';

import { logout } from 'src/store/actions/auth';
import authSelectors from 'src/store/selectors/auth';
import getInitials from 'src/utils/getInitials';

import NavItem from './NavItem';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const items = [
  {
    href: '/places',
    icon: MapPinIcon,
    title: 'Miejsca'
  },
  {
    href: '/categories',
    icon: CategoriesIcon,
    title: 'Kategorie'
  },
  {
    href: '/tags',
    icon: TagIcon,
    title: 'Tagi'
  },
  {
    href: '/users',
    icon: UsersIcon,
    title: 'Użytkownicy'
  },
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(1),
  }
}));

const NavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobileMenuOpen, setMobileMenuOpen } = useContext(AppBarContext);
  const user = useSelector((state) => authSelectors.getUser(state));

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout);
    window.location = '/login';
  };

  useEffect(() => {
    if (isMobileMenuOpen && setMobileMenuOpen) {
      handleMobileMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar && `${STORAGE_URL}/users/${user.id}/${user.avatar}`}
          to={`/user/${user.id}`}
        >
          {!user.avatar && getInitials(user.username)}
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.username}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Hidden lgUp>
        <Divider />
        <Box p={2}>
          <List>
            <NavItem
              icon={LogoutIcon}
              onClick={handleLogout}
              title="Wyloguj"
            />
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={handleMobileMenuClose}
          open={isMobileMenuOpen}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
